<template>
    <div>
      <v-row wrap justify="center" style="background-color: #f0f0f0" class="pt-lg-8">
        <v-col cols="12" class="text-center py-4 pt-8">
          <span class="headingChurch">
            Privacy Policy
          </span>
        </v-col>
        <v-col cols="10" sm="11" md="10" lg="11" xl="11" class="text-justify" style="font-family:interregular">
          <span style=" font-size: 16px; color: #1a1919;font-family:interregular">
           
           
            Welcome to My Jesus App. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to safeguard your data.
            <br /><br />
            <span style="font-weight: bold;">1. Information We Collect:</span>
            <br />
            <ul>
              <li>
                Personal Information: When you engage with My Jesus App, we may collect personal information such as your name, email address, phone number, postal address, and other relevant details necessary for providing our services.
              </li>
              
              <li>
                Usage Data: We may collect information about how you interact with our website, including IP addresses, browser types, operating systems, referring pages, and the timestamps of visits.
              </li>
              <li>
                Cookies: We use cookies and similar tracking technologies to enhance your browsing experience and collect information about your preferences and activities on our website.
              </li>
            </ul>
            <br />
            <span style="font-weight: bold;">2. How We Use Your Information:</span>
            <br />
            <ul>
              <li>
                We may use the information collected to provide, maintain, and improve our services, including customer support, marketing, and communication purposes.
              </li>
              <li>
                Your personal information may be used to process transactions, send invoices, and manage your account.
              </li>
              <li>
                We may use usage data and cookies to analyze trends, administer the website, track users' movements around the site, and gather demographic information.
              </li>
            </ul>
            <br />
            <span style="font-weight: bold;">3. Data Sharing and Disclosure:</span>
            <br />
            <ul>
              <li>
                My Jesus App does not sell, rent, or lease your personal information to third parties.
              </li>
              <li>
                We may share your information with trusted third-party service providers who assist us in operating our website, conducting our business, or serving you, provided that they agree to keep your information confidential.
              </li>
              <li>
                We may disclose your personal information if required by law or in response to valid legal requests.
              </li>
            </ul>
            <br />
            <span style="font-weight: bold;">4. Data Security:</span>
            <br />
            <ul>
              <li>
                We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.
              </li>
              <li>
                However, no method of transmission over the internet or electronic storage is completely secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
              </li>
            </ul>
            <br />
            <span style="font-weight: bold;">5. Your Rights:</span>
            <br />
            <ul>
              <li>
                You have the right to access, correct, or delete your personal information. If you wish to exercise these rights, please contact us using the contact details provided below.
              </li>
              <li>
                You may opt-out of receiving marketing communications from us by following the unsubscribe instructions included in such communications.
              </li>
            </ul>
            <br />
            
            <span style="font-weight: bold;">7. Changes to This Privacy Policy:</span>
            <br />
            <ul>
              <li>
                We reserve the right to update or change our Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on this page.
              </li>
            </ul>
            <br />
            

            By using our services, you consent to the collection and use of your personal information as outlined in this Privacy Policy. Thank you for entrusting My Jesus App with your business needs.
            <br /><br />
            
          </span>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PrivacyPolicy'
  };
  </script>
  
  <style scoped>
  .text-justify {
    text-align: justify;
  }
  
  .text-center {
    text-align: center;
  }
  
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .pt-8 {
    padding-top: 2rem;
  }
  

  </style>
  